.contactContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 3;
  position: relative;
  width: 100%;
  padding: var(--site-margin);
}
.contactInfo {
  flex: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.darkContainer {
  animation: var(--form-animation);
}
.contactContainer form {
  padding: 4rem;
  border: 5px solid var(--secondary);
  background: var(--contact-form-bg);
  display: flex;
  flex-direction: column;
  min-width: 600px;
  height: auto;
  border-radius: 10px;

  /* border-top: 8px solid var(--dark);
  border-left: 3px solid var(--dark);
  border-right: 3px solid var(--dark);
  border-bottom: 8px solid var(--dark); */
}
.contactContainer form h1 {
  color: var(--secondary);
  font-family: var(--font-teko);
  text-align: center;
  font-size: 5rem;
  line-height: 5rem;
  margin: 0;
  animation: var(--form-h1-animation);
  letter-spacing: 0px;
  font-weight: bolder;
}
.offset {
  animation: letter-flicker 2s linear infinite;
  color: var(--primary);
}
.contactContainer form p {
  color: var(--primary);
  text-align: center;
  font-size: var(--font-size);
  margin: 1rem 0 1.5rem 0;
}
.contactContainer form hr {
  width: 100%;
  margin: 0 0 1rem 0;
  color: var(--card-color);
}
.contactContainer form input {
  font-size: var(--font-size);
  padding: 0.7rem;
  border-radius: 5px;
  border: 2px solid transparent;
  margin: 0.5rem auto;
  background-color: var(--dark);
  color: var(--light);
  width: 400px;
}
.contactContainer form input:hover {
  border: 2px solid var(--secondary);
}
.contactContainer form textarea:hover {
  border: 2px solid var(--secondary);
}
.contactContainer form input:active {
  border: 2px solid var(--secondary) !important;
}
.contactContainer form textarea {
  margin: 1rem auto;
  padding: 0.7rem;
  font-size: var(--font-size);
  border-radius: 5px;
  border: none;
  background-color: var(--dark);
  color: var(--light);
  width: 400px;
  border: 2px solid transparent;
}
.contactContainer form input::placeholder {
  color: var(--light);
}
.contactContainer form textarea::placeholder {
  color: var(--light);
}
.contactContainer form input:last-child {
  margin: 0;
}
.submit {
  background-color: var(--secondary);
  padding: 1rem 3rem;
  border-radius: 5px;
  margin: 1rem auto;
  border: none;
  color: var(--dark);
  font-size: 1.3rem;
  width: 400px;
}

@media screen and (max-width: 1000px) {
  .contactContainer {
    margin-bottom: 5rem;
  }
  .contactContainer form {
    min-width: unset;
  }
}
@media screen and (max-width: 800px) {
  .contactContainer form {
    width: 500px;
  }
}
@media screen and (max-width: 600px) {
  .contactContainer form {
    padding: 4rem 2rem;
  }
  .contactContainer form h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  .contactContainer form input {
    width: 80%;
  }
  .contactContainer form textarea {
    width: 80%;
  }
  .contactContainer form button {
    width: 80%;
  }
}
/*--animations --*/
