.dripContainer {
  position: fixed;
  z-index: 3;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.drip {
  display: block;
  position: absolute;
  height: 80px;
  width: 2px;

  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    var(--secondary) 75%,
    var(--secondary) 100%
  );
  animation: drop 7s 0s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.noDrip {
  display: none;
}
@keyframes drop {
  0% {
    top: -50%;

    opacity: 0.2;
  }
  50% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.1;
  }
  100% {
    top: 105vh;
    opacity: 0;
  }
}
