.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container h1 {
  font-size: var(--title-size);
  line-height: var(--title-height);
}
.container p {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}
.innerMentor {
  width: var(--max-width);
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  z-index: 5;
  position: relative;
  align-items: center;
  flex-wrap: wrap;
}
/* .mentor:first-child {
  border-top: 3px solid var(--secondary);
  border-right: 3px solid var(--secondary);
  border-left: 3px solid var(--secondary);
  border-bottom: none !important;
}
.mentor:last-child {
  border-bottom: 3px solid var(--secondary);
  border-right: 3px solid var(--secondary);
  border-left: 3px solid var(--secondary);
  border-top: none !important;
} */
.mentor {
  display: flex;
  flex-direction: row;

  height: auto;
  margin: 2rem 0;
  width: 50%;
  align-items: center;
  background: var(--cardbg);

  /* border-radius: 10px; */
  padding: 0 2rem 0 0;
  box-shadow: 10px 10px 50px var(--secondaryop);
}

.mentor img {
  height: 300px;
  width: auto;
  margin: 0 3rem 0 0;
}
.mentorInfo {
  width: 500px;
  display: flex;
  flex-direction: column;
}
.mentorInfo p {
  font-size: var(--text-size);
  line-height: var(--text-height);
  margin-top: 0.5rem;
  text-align: left;
  width: 100%;
}
.mentorInfo h1 {
  font-size: 3rem;
  line-height: 1.5rem;
}
.mentorInfo hr {
  border: none;
}
.mentorLink {
  background-color: var(--secondary);
  padding: 0.8rem 1.5rem;
  box-sizing: border-box;
  margin-top: 2rem;
  color: var(--background);
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
  .innerMentor {
    width: 90%;
  }
  .mentor {
    min-width: 800px;
  }
}

@media screen and (max-width: 1000px) {
  .mentor {
    min-width: 600px;
  }
  .mentorInfo h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 800px) {
  .mentor {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    min-width: unset;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 1rem;
  }
  .mentor img {
    margin-right: 0;
    border-bottom: 3px solid var(--secondary);
  }
  .mentorInfo {
    text-align: center;
  }
  .mentorInfo hr {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .mentorInfo p {
    text-align: center;
  }
  .mentorInfo h1 {
    margin-top: 2rem;
  }
}
