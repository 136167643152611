.ctaContainer {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border-top: 4px solid var(--secondary);
  border-bottom: 4px solid var(--secondary); */
  z-index: 5;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--card-color);
  opacity: 0.8;
}
.ctaContainer hr {
  color: var(--secondary);
  background-color: var(--secondary);
  z-index: 6;
  border: none;
  height: 3px;
  margin: 10px;
}
.ctaContainer h1 {
  z-index: 6;
  margin-bottom: 1rem;
  font-size: var(--title-size);
  text-transform: uppercase;
  color: var(--light);
  text-align: center;
}
.ctaContainer p {
  z-index: 6;
  margin: 1rem 0 2rem 0;
  font-size: var(--font-size);
  line-height: var(--font-height);
  text-align: center;
  width: 60%;
  color: var(--light);
}
.ctaContainer a {
  z-index: 6;
  padding: 1rem 2.5rem;
  color: var(--textcolor);
  background-color: var(--background);
  text-transform: uppercase;
  position: relative;
  margin-top: 0.8rem;
}
/* .ctaContainer a:hover {
  padding: 1.1rem 2.6rem;

  transition: 0.2s padding ease-in-out;
} */
@media screen and (max-width: 600px) {
  .ctaContainer {
    height: auto;
    padding: 3rem var(--site-margin);
  }
  .ctaContainer h1 {
  }
  .ctaContainer p {
    width: 100%;
  }
}
