.registerContainer {
  display: flex;
  position: relative;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 200px;
}
.registerContainer hr {
  margin: 2rem auto;
}
.err {
  border: 2px solid var(--light) !important;
}
.errorMessage {
  font-size: 0.8rem;
  color: var(--light);
}
.registerContainer h1 {
  margin: 1rem 0;
}
.registerForm {
  display: flex;
  flex-direction: column;

  padding: 2rem 0 3rem 0;
  /* background: rgb(31, 43, 55);
  background: var(--cardbg); */
  background: var(--cardbg);
  box-shadow: var(--register-box-shadow);
  min-width: 600px;
  height: auto;
  border-radius: 10px;
  align-items: center;
  z-index: 5;
  border: 2px solid var(--secondary);
  border-bottom: 8px solid var(--secondary);
  border-left: 5px solid var(--secondary);
  margin-top: 1rem;
  transition: box-shadow 1s ease-in-out;
}

.span {
  font-size: 6rem !important;
  line-height: 4.8rem !important;
  /* text-shadow: 4px 4px 40px var(--secondary); */
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px !important;
}
.registerTitle {
  font-size: 4rem;
  line-height: 4.4rem;
  width: 80%;
  margin: 0 auto 0 auto !important;
  text-align: left;
}
.registerForm h1 {
  margin: 1rem 0 2rem 0;
  z-index: 5;
  color: var(--light);
  font-size: 3rem;
}
.inputRow {
  display: flex;
  flex-direction: row;
  width: 60%;
  margin: 0.5rem auto;
  height: 40px;
}
.inputSVGContainer {
  height: 100%;
  background-color: var(--secondary);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 40px;
}
.inputRow svg {
  height: 20px;
  width: auto;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  color: var(--light);
}
.inputRow input {
  font-size: var(--font-size);
  padding: 0.5rem 0.8rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 2px solid transparent;
  width: 100%;
  background-color: var(--background);
  color: var(--textcolor);
  font-family: monospace;
}
.inputRow input:focus {
  outline: none;
  border: 2px solid var(--secondary);

  transition: 0.3s border ease-in;
}
.inputRow input::placeholder {
  font-size: 0.8rem;
}
.submit {
  background-color: var(--secondary);
  padding: 1rem 3rem;
  border-radius: 5px;
  margin: 2rem auto 1rem auto;
  border: none;
  color: var(--dark);
  font-size: 1.3rem;
  width: 60%;
  cursor: pointer;
}
.disabled {
  background-color: var(--dark);
  padding: 1rem 3rem;
  border-radius: 5px;
  margin: 2rem auto 1rem auto;
  border: none;
  color: var(--light);
  font-size: 1.3rem;
  width: 60%;
  text-decoration: line-through;
}
.googleSignup {
  background: var(--cardbg);
  width: 600px;
  text-align: center;
  padding: 2rem;
  line-height: 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  z-index: 5;
  cursor: pointer;
  border: 3px solid var(--secondary);
  border-bottom: 5px solid var(--secondary);
}
.googleSignup a {
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--light);
}
.googleSignup img {
  position: absolute;
  left: 3rem;
  height: 40px;
  /* left: 2rem;
  height: 80%;
  top: 0.4rem; */
}
.blob {
  position: absolute;
  right: 30%;
  bottom: 2rem;
  opacity: 0.05;
  width: 40%;
  aspect-ratio: 1/1;

  filter: blur(30px);
  z-index: -1;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  border-radius: 70% 79% 70% 40%;
  z-index: 0;
}

@media screen and (max-width: 1000px) {
  .registerTitle {
    font-size: 3rem;
    line-height: 3rem;
  }
  .span {
    font-size: 4rem !important;
    line-height: 4rem !important;
  }
}
@media screen and (max-width: 800px) {
  .registerContainer {
    padding-top: 130px;
  }
  .registerForm {
    min-width: unset;
    width: 80%;
  }
  .googleSignup {
    width: 80%;
  }
  .inputRow {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .registerTitle {
    font-size: 2rem;
    line-height: 2rem;
    width: 100%;
    margin: 0 !important;
    padding-left: var(--site-margin);
    padding-right: var(--site-margin);
  }
  .registerTitle br {
    display: none;
  }
  .span {
  }

  .registerForm h1 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    width: 80%;
  }
  .inputRow {
    width: 80%;
  }
  .googleSignup {
    justify-content: space-around;
  }
  .googleSignup a {
    font-size: 1rem;
  }
  .googleSignup img {
    position: relative;
    left: unset;
  }
}
@media screen and (max-width: 400px) {
  .registerForm {
    width: 90%;
  }
  .inputRow {
    width: 90%;
  }
  .registerForm h1 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .span {
    font-size: 3rem !important;
    line-height: 3rem !important;
  }
  .googleSignup {
    padding: 2rem 1rem;
  }
}
