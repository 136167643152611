.outerHor {
  height: 600vh;
  position: relative;
  overflow: visible;

  width: 100%;
  z-index: 6;
  background-color: var(--tertiary);
  margin-top: 20rem;
}
.outerHor h1 {
  font-size: var(--title-size);
  line-height: var(--title-height);
  margin-left: var(--site-margin);
  color: var(--textcolor);
}
.outerHor p {
  margin-left: var(--site-margin);
  font-size: var(--font-size);
  line-height: var(--font-height);
  width: 80%;
  color: var(--textcolor);
}
.outerHor hr {
  margin-left: calc(var(--site-margin) + 1rem);
  border: none;
}
.ourSoftware {
  font-size: 2rem;
  text-transform: uppercase;
  position: absolute;
  top: 6rem;
  left: var(--site-margin);
  color: var(--textcolor);
}
.hor {
  position: sticky;
  top: 0;
  width: 300vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  text-align: center;
  /* margin-left: 2rem; */
  overflow-x: hidden;
}
.horItem:hover {
  box-shadow: var(--secondary) 0px 0px 2px 5px;
  transition: box-shadow border-color 1s ease-in-out;
  border-color: var(--secondary);
}

.horItem {
  transition: box-shadow 1s ease-in-out;
  margin: 10rem 1rem 1rem 5rem;
  /* height: 300px; */
  /* width: 1000px; */

  box-sizing: border-box;
  background: linear-gradient(10deg, var(--shadow), #1d1e20);

  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2rem 3rem;

  border: 6px solid var(--secondary);
  border-radius: 10px;
}
.horItem p {
  text-align: left;
  color: var(--light);
  margin-left: 0;
  width: 100%;
}
.horItem h1 {
  font-size: 2.7rem;
  line-height: 3rem;
  z-index: 3;
  color: var(--light);
  text-transform: uppercase;
  text-wrap: nowrap;
  margin-left: 2rem;
  text-align: center;
  margin-top: 2rem;
  text-shadow: 3px 3px 4px var(--dark);
}
.horItem svg {
  font-size: 70px;
  margin-bottom: 0;
  border: 2px solid var(--secondary);
  border-radius: 100%;
  padding: 1rem 3rem 1rem 3rem;
  box-shadow: 0px 9px 0px 0px var(--secondary);
  color: var(--secondary);
  /* -3px 2px 6px 2px var(--primary), 25px -4px 6px 2px var(--secondary),
    35px 1px 5px 2px var(--secondary); */
}

.horItem hr {
  margin: 0 0 1rem 0;
  width: 100%;
  margin-left: 0%;
  color: var(--textcolor);
  background-color: var(--secondary);
  border-top: 0;
  border: none;
}

@media screen and (max-width: 1200px) {
  .horItem h1 {
    text-wrap: wrap;
    line-height: 2.3rem;
    font-size: 1.7rem;
    text-align: left;
  }
}
@media screen and (max-width: 1000px) {
  .horItem {
    width: 600px;
    flex-direction: column;
    align-items: center;

    padding: 3rem 0;
    height: auto;
  }
  .horItem h1 {
    width: 90%;
    margin: 0;
    text-align: center;
  }
  .horItem svg {
    font-size: 70px;
  }
  .horItem h1 br {
    display: none;
  }
  .horItem p {
    text-align: left;
    width: 90%;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .horItem {
    padding: 2rem 1rem;
    min-height: 300px;
    width: 80vw;
    justify-content: flex-start;
  }
  .horItem h1 {
    font-size: 1.5rem;
    line-height: 1.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .horItem svg {
    font-size: 50px;
    margin-bottom: 0.3rem;
    padding: 0;
  }
  .horItem p {
    margin-top: 0.6rem;
    line-height: 1.6rem;
  }
  .ourSoftware {
    max-width: 400px;
    font-size: 1.2rem;
    text-align: left;
  }
}
@keyframes border-flicker {
  0% {
    border-color: #43b7cc;
    -webkit-box-shadow: 0 0 15px rgba(67, 183, 255, 0.2),
      inset 0 0 15px rgba(67, 183, 255, 0.1), 0 2px 0 #000;
    box-shadow: 0 0 15px rgba(67, 183, 255, 0.2),
      inset 0 0 15px rgba(67, 183, 255, 0.1), 0 2px 0 #000;
  }
  15% {
    border-color: #56becc;
    -webkit-box-shadow: 0 0 20px rgba(67, 183, 255, 0.6),
      inset 0 0 20px rgba(67, 183, 255, 0.4), 0 2px 0 #000;
    box-shadow: 0 0 20px rgba(67, 183, 255, 0.6),
      inset 0 0 20px rgba(67, 183, 255, 0.4), 0 2px 0 #000;
  }
  16% {
    border-color: transparent;
    -webkit-box-shadow: 0 0 40px transparent, inset 0 0 20px transparent,
      0 2px 0 transparent;
    box-shadow: 0 0 40px transparent, inset 0 0 20px transparent,
      0 2px 0 transparent;
  }
  17% {
    border-color: #43b7cc;
    -webkit-box-shadow: 0 0 15px rgba(67, 183, 255, 0.2),
      inset 0 0 15px rgba(67, 183, 255, 0.1), 0 2px 0 #000;
    box-shadow: 0 0 5px rgba(67, 183, 255, 0.2),
      inset 0 0 15px rgba(67, 183, 255, 0.1), 0 2px 0 #000;
  }
  20% {
    border-color: #56becc;
    -webkit-box-shadow: 0 0 20px rgba(67, 183, 255, 0.6),
      inset 0 0 20px rgba(67, 183, 255, 0.4), 0 2px 0 #000;
    box-shadow: 0 0 20px rgba(67, 183, 255, 0.6),
      inset 0 0 20px rgba(67, 183, 255, 0.4), 0 2px 0 #000;
  }
  30% {
    border-color: #43b7cc;
    -webkit-box-shadow: 0 0 15px rgba(67, 183, 255, 0.2),
      inset 0 0 15px rgba(67, 183, 255, 0.1), 0 2px 0 #000;
    box-shadow: 0 0 15px rgba(67, 183, 255, 0.2),
      inset 0 0 15px rgba(67, 183, 255, 0.1), 0 2px 0 #000;
  }
  100% {
    border-color: #56becc;
    -webkit-box-shadow: 0 0 20px rgba(67, 183, 255, 0.6),
      inset 0 0 20px rgba(67, 183, 255, 0.4), 0 2px 0 #000;
    box-shadow: 0 0 20px rgba(67, 183, 255, 0.6),
      inset 0 0 20px rgba(67, 183, 255, 0.4), 0 2px 0 #000;
  }
}
