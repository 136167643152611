.navbarContainer {
  position: fixed;
  top: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  /* padding: 0 var(--site-margin); */
  z-index: 10;
  max-width: var(--max-width);
  width: var(--max-width);
  padding: 0;

  margin: 0 calc(100vw - 1600 / 2) 0 calc(100vw - 1600 / 2);
}
.navLogo {
  height: 30px;
  width: auto;
}
.navLogoLink {
  height: 70%;
}
.stickyNav {
  position: fixed;
}
.navbarContainer div {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.closedNav {
  position: fixed;
  top: 0;
  left: -300px;
  width: 0%;
  height: 100vh;
  overflow: hidden;
}
.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #3333338a;
  animation: 0.3s fadeIn;
  z-index: 4;
}
.openedNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 40%;
  max-width: 1400px;
  height: 100vh !important;
  background-color: var(--dark);
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: flex-start;
  border-right: 20px solid var(--secondary);
  z-index: 1090;
}

.openedNav h1 {
  font-size: 1rem;
  margin: 0 0 2rem 0;
  text-align: center;
  color: var(--light);
}
.openLogo {
  width: 200px;
  height: auto;
  display: none;
  margin: 3rem 0 2rem 0;
}
.openedNav ul {
  width: 100%;
}

.openedNav ul li {
  list-style-type: none;
  margin: 0 0;
  font-size: 1.7rem;
  color: var(--light);
  width: 100%;
  border-bottom: 2px solid var(--secondary);
}
.openedNav ul li a {
  color: var(--light);
  width: 100%;
  height: 100%;
  display: block;
  padding-bottom: 1.5rem;
  padding: 1.5rem 2rem 1.5rem 2rem;
}
.openedNav ul li:hover {
  background-color: var(--secondary);
  transition: background-color 0.3s ease-in;
  color: var(--dark);
  /* box-shadow: inset var(--dark) 5px 5px 10px 0px; */
}
.themeToggle {
  height: 100%;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--textcolor);
}
.themeToggle small {
  margin-right: 1rem;
  font-size: 0.8rem;
}
.themeToggle div {
  height: 100%;
}
.themeToggle button {
  height: 100%;
}
.themeToggle button svg {
  height: 40%;
  width: auto;
}
@media screen and (max-width: 1700px) {
  .navbarContainer {
    padding: 0 var(--site-margin);
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .themeToggle {
    min-width: 75px;
  }
  .openedNav {
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  .themeToggle small {
    margin-right: 0.5rem;
    font-size: 0.7rem;
  }
  .themeToggle {
    min-width: 40px;
  }
  .openedNav {
    width: 70%;
  }
}
@media screen and (max-width: 500px) {
  .themeToggle small {
    display: none;
  }
  .themeToggle {
    width: auto;
    min-width: 40px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
