.neuronsContainer {
  position: relative;
  /* background-color: var(--background); */
  width: 100vw;
  padding-bottom: 3rem;
}

.neuronTitle {
  width: auto;
}

.textContainer {
  /* margin-left: var(--site-margin);
  margin-right: var(--site-margin); */
  /* margin-top: -5px; */
  min-height: 300px;
  min-width: 90%;
  overflow: hidden;

  max-width: var(--max-width);
  margin: 0 auto;
}
.textContainer hr {
  border: none;
}
.splashText {
  /* background-color: var(--card-color); */
  padding: 2rem var(--site-margin) 0 var(--site-margin);
  position: relative;
  z-index: 2;
  /* border: 5px solid var(--light); */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* border-top-left-radius: 30px; */
  border-bottom-left-radius: 30px;
  z-index: 2;
  overflow: hidden;

  /* border-left: 4px solid var(--secondary); */
}
.list {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-around;
  align-items: center;

  width: 49%;
  /* border-top-right-radius: 30px; */
  border-bottom-right-radius: 30px;
  padding: 3rem;
  margin-left: 1%;
  z-index: 3;
  overflow: hidden;

  /* border-right: 4px solid var(--secondary); */
}
.listItem {
  padding: 1rem;
  /* background-color: var(--background); */
  border-bottom: 1px solid var(--secondary);
  width: 100%;
}
.listItem h1 {
  font-size: 1.3rem;
  color: var(--textcolor);
  text-wrap: nowrap;
}
.splashText p {
  width: 100%;
  font-size: var(--font-size);
  line-height: var(--font-height);

  text-align: left;
  text-wrap: wrap;
}
.splashText h1 {
  font-size: var(--title-size);
  margin: 2rem 0 0 0;
  line-height: var(--title-height);
}
.splashText h1 span {
  color: var(--secondary);
}
.textContainer {
  display: flex;
  flex-direction: row;
}
.neuronSVG {
  height: 60vh;
  width: auto;
  margin: 0;
  position: relative;
  z-index: 0;
  border-bottom: 4px solid var(--secondary);
}
.siteOverlay {
  background-size: 30px 30px;

  background-image: var(--site-overlay-background);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: 2;
}

@media screen and (max-width: 1200px) {
  .neuronSVG {
    width: auto !important;
    height: 80vh !important;
  }
}
@media screen and (max-width: 1000px) {
  .neuronSVG {
    width: auto !important;
    height: 40vh !important;
  }
  .splashText {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .neuronSVG {
    width: auto !important;
    height: 60vh !important;
  }
  .textContainer {
    flex-direction: column;
    align-items: center;
  }
  .splashText {
    width: 100%;
  }
  .list {
    width: 100%;
    padding: 3rem var(--site-margin);
  }
}
