.blandSplashContainer {
  height: 100vh;
  max-height: 1200px;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: visible;
  max-width: var(--max-width);
  width: var(--max-width);
  position: relative;
  margin: 0 auto;
}

.textContainer {
  z-index: 2;
}
.heroImage {
  width: 800px;
  height: auto;
  position: absolute;
  right: 0;
  z-index: 3;
}
.textContainer h1 {
  font-size: 5rem;
  line-height: 6.2rem;
  z-index: 3;
}
/* .threeD {
  white-space: nowrap;
  position: relative;

  font-family: sans-serif;
  letter-spacing: 0.1em;
  transition: 0.3s;
  text-shadow: 1px 1px 0 var(--background), 1px 2px 0 var(--background),
    1px 3px 0 var(--background), 1px 4px 0 var(--background),
    1px 5px 0 var(--background), 1px 6px 0 var(--background),
    1px 7px 0 var(--background), 1px 8px 0 var(--background),
    5px 13px 15px black;
} */

/* .threeD:hover {
  transition: 0.3s;
  transform: scale(1.1) translate(-50%, -50%);
  text-shadow: 1px -1px 0 var(--background), 1px -2px 0 var(--background),
    1px -3px 0 var(--background), 1px -4px 0 var(--background),
    1px -5px 0 var(--background), 1px -6px 0 var(--background),
    1px -7px 0 var(--background), 1px -8px 0 var(--background),
    5px -13px 15px black, 5px -13px 25px #808080;
} */

.threeD {
  font-size: 7rem !important;
  line-height: 5.8rem !important;
  /* text-shadow: 4px 4px 40px var(--secondary); */
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px !important;
  margin: 0rem;
}
.blob1 {
  position: absolute;
  right: -5rem;
  bottom: 2rem;
  opacity: 0.4;
  width: 40%;
  aspect-ratio: 1/1;
  animation: animate 10s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite
    alternate forwards;
  filter: blur(40px);
  z-index: -1;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  border-radius: 70% 79% 40% 100%;
  z-index: 0;
}
.blob2 {
  position: absolute;
  left: -40rem;
  top: 20rem;
  opacity: 0.4;
  width: 40%;
  aspect-ratio: 1/1;
  animation: animate 10s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite
    alternate forwards;
  filter: blur(40px);
  z-index: -1;
  background: linear-gradient(45deg, var(--background), var(--span-color));
  border-radius: 70% 79% 40% 100%;
  z-index: 0;
}
/* @keyframes animate {
  0% {
    translate: 40% -25%;
    border-radius: 60% 40% 30% 70% / 100% 85% 92% 74%;
  }
  50% {
    translate: 0% 13%;
    border-radius: 20% 71% 47% 70% / 81% 15% 22% 54%;
    rotate: 41deg;
    scale: 1.15;
  }
  100% {
    translate: -45% 39%;
    border-radius: 100% 75% 92% 74% / 60% 80% 30% 70%;
    rotate: -60deg;
    scale: 1.05;
  }
} */
.textContainer p {
  margin-top: 1rem;
}
@media screen and (max-width: 1700px) {
  .blandSplashContainer {
    width: 100%;
    padding: 2rem var(--site-margin);
  }
}
@media screen and (max-width: 1600px) {
  .blandSplashContainer {
    padding-top: 7rem;
  }
  .textContainer h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .heroImage {
    width: 700px;
    height: auto;
    bottom: 5rem;
    right: 7rem;
  }
}
@media screen and (max-width: 1400px) {
  .heroImage {
    width: 600px;
    bottom: 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .blandSplashContainer {
    padding-top: 10rem;
    align-items: flex-start;
  }
}
@media screen and (max-width: 1000px) {
  .blandSplashContainer {
    flex-direction: column;
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
  }
  .heroImage {
    right: 0;
    position: relative;
    margin-left: 20%;
  }
  .textContainer h1 {
    font-size: 2rem;
  }
  .threeD {
    font-size: 5rem !important;
    line-height: 4rem !important;
  }
}
@media screen and (max-width: 800px) {
  .heroImage {
    width: 500px;
    margin-left: 0;
    margin-top: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .textContainer h1 {
    line-height: 2rem;
  }
  .threeD {
    font-size: 4rem !important;
    line-height: 3.9rem !important;
    margin-top: 0;
  }
  .textContainer p {
    margin-top: 0.2rem;
  }
}
@media screen and (max-width: 400px) {
  .heroImage {
    width: 400px;
    margin-left: -1rem;
    margin-top: 8rem;
  }
  .textContainer h1 {
    line-height: 2rem;
    font-size: 1.5rem;
  }
  .threeD {
    font-size: 3.2rem !important;
    line-height: 2.8rem !important;
  }
}
