.solveNowContainer {
  position: relative;
  width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 100px 0;
  margin: 0 auto;
  /* background-color: var(--card-color); */
}
.solveNowContainer h1 {
  font-size: var(--title-size);
  line-height: var(--title-height);
}
.solveNowContainer p {
  margin: 1rem 0;
  font-size: var(--font-size);
  line-height: var(--font-height);
}
.solveNowContainer hr {
  border: none;
}
@media screen and (max-width: 1700px) {
  .solveNowContainer {
    width: 100%;
    padding: 100px var(--site-margin);
  }
}
