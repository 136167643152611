.footerContainer {
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  padding: 3rem var(--site-margin);
  position: relative;

  left: 0;
  z-index: 10;
  margin-top: 0;
}
.footerLogo {
  width: 250px;
  height: auto;
}
.footerContainer p {
  color: var(--textcolor);
}
.footerContainer svg {
  color: var(--textcolor);
}
.footerText {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.footerText p {
  font-size: 1.5rem;
  margin: 0;
  line-height: 1.8rem;
}
.footerText small {
  font-size: 0.8rem;
  color: var(--textcolor);
}
.footerText small a {
  color: var(--textcolor);
}
.footerText hr {
  border: none;
  margin: 15px 0 12px 0;
}
.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logoContainer div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logoContainer div p {
  margin: 0.5rem 0 1rem 0;
}
@media screen and (max-width: 1000px) {
  .footerContainer {
    justify-content: space-between;
  }
  .footerText {
    width: 50%;
  }
}
@media screen and (max-width: 800px) {
  .footerText p {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}
@media screen and (max-width: 600px) {
  .footerContainer {
    flex-direction: column;
    height: auto;
    min-height: 500px;
    justify-content: space-around;
  }
  .footerText {
    width: 80%;
  }
}
