.container {
  width: 100%;
  margin: 0 auto;
  /* background: var(--cardbg); */
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.sideNav {
  position: relative;
  height: 100vh;
  min-width: 50px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 10;
  left: 0;
  top: 0;
  background-color: var(--background);
  border-right: 3px solid var(--light);
}
.sideNav ul {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.sideNav ul li {
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  padding: 0.5rem 0 0.5rem 1rem;
  cursor: pointer;
  z-index: 2;
}
.sideNav ul li h1 {
  color: var(--textcolor);
  /* font-family: var(--font-roboto); */
  font-size: 1.1rem;
  margin: 0.2rem 1rem 0rem 1rem;
}
.sideLogo {
  height: auto;
  width: 80%;
  position: relative;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2rem;
}
.subMenu {
  z-index: 0;
  top: 50px;
  left: 0px !important;
  position: absolute !important;
  background-color: var(--dark);
  min-height: 200px;
  min-width: 200px;
  width: 100%;
}
/* .subMenu li {
  z-index: 100;
  background-color: none !important;
} */
.sideNav ul li svg {
  /* position: absolute;
  right: 1rem; */
  height: 20px;
}
.user {
  display: flex;
  color: var(--textcolor);
  flex-direction: row;
  align-items: center;

  width: 80%;
  margin-top: 2rem;
  justify-content: space-around;

  margin-bottom: 2rem;
}
.user svg {
  font-size: 20px;
  margin-bottom: 2rem;
}
.user p {
  font-size: 12px;
}
.clientHome {
  width: var(--max-width);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 1600px) {
  .clientHome {
    width: 80%;
  }
}
