.containsContainer {
  display: flex;
  width: var(--max-width);
  margin: 0 auto;
  /* align-items: center; */
  flex-direction: column;
  text-align: left;
  margin-top: 4rem;
}
.containsHeading {
  font-size: var(--title-size);
}
.boxesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4rem 0 2rem 0;
}
.boxesContainer div {
  width: 500px;
  background-color: var(--background);
  padding: 2rem;

  border-top: 20px solid var(--secondary);
  border-left: 1px solid var(--secondary);
  border-right: 1px solid var(--secondary);
  border-bottom: 5px solid var(--secondary);

  color: var(--light);
  height: auto;
  /* border-radius: 5px; */
  /* box-shadow: 0px 0px 100px 0px var(--secondaryop); */
  z-index: 4;
}
.boxesContainer div h3 {
  font-size: 2rem;
  line-height: 2.3rem;
}

@media screen and (max-width: 1700px) {
  .containsContainer {
    width: 100%;
    padding: 0 var(--site-margin) 0 var(--site-margin);
  }
  .boxesContainer div {
    width: 33%;
  }
}
@media screen and (max-width: 1400px) {
  .boxesContainer div {
    width: 50%;
  }
}
@media screen and (max-width: 700px) {
  .boxesContainer div {
    width: 100%;
  }
  .boxesContainer div h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
