.whatWillContainer {
  width: var(--max-width);
  padding: 5rem 0;
  margin: 0 auto;
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  /* background-color: var(--background); */
}
.textContainer h1 {
  font-size: var(--title-size);
  line-height: var(--title-height);
  width: 100%;
  text-wrap: balance;
}
.textContainer hr {
  border: none;
}
.textContainer p {
  margin: 1rem auto;
  font-size: var(--font-size);
  line-height: var(--font-height);
}
.textContainer {
  flex: 50%;

  /* border-top: 4px solid var(--secondary);
  border-bottom: 4px solid var(--secondary); */
  border-radius: 30px;
  padding: 6rem 0;
}
.imageContainer {
  background-image: url("/src/assets/images/road.jpeg");
  background-size: cover;
  flex: 50%;
  height: 250px;
  width: 40%;
  float: right;
  background-repeat: no-repeat;

  margin: 3rem 0 3rem 3rem;
  border-radius: 30px;
}
@media screen and (max-width: 1700px) {
  .whatWillContainer {
    width: 100%;
    padding: 5rem var(--site-margin);
  }
}
@media screen and (max-width: 1000px) {
  .textContainer {
    width: 100%;
    flex: 100%;
    flex-direction: column;
  }
  .textContainer h1 {
    width: 100%;
  }
  .imageContainer {
    float: none;
    width: 100%;
    height: 250px;
    margin: 1rem 0;
    border-radius: 10px;
  }
}
@media screen and (max-width: 600px) {
  .imageContainer {
    height: 130px;
  }
}
